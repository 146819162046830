

:root {
  --main-purple: #e20cdb;
  --dark: #2e2c3d;
}
body {
  background-color: var(--dark);
}
*:not(i) {
  font-family: 'Oswald', sans-serif !important;
}
.ft-lato,p {
  font-family: 'Lato', sans-serif !important;
}

.bg-purple{
    background-color:var(--main-purple);
}
  .btn.bg-purple:hover{
      background-color:white;
     
  }
    .btn.bg-purple:hover span {
      color: var(--dark);
    }

.bg-dark {
  background-color: var(--dark);
}

.text-purple{
    color:var(--main-purple);
}


.notoemoji {
  font-family: 'Noto Color Emoji', sans-serif !important;
}
.bg2 {

  background-image: url('/assets/img/webp/Section 2.webp');
  background-size:cover;
  background-repeat:no-repeat;
}

.bg4 {
  background-image: none;
  background-size: cover;
  background-repeat: no-repeat;
}
.black-border {
  border-radius: 90%;
  border: 8px solid var(--dark);
}
.quantity{
    border:1px solid white;
    border-radius:15px;
    display:inline-block;
    padding:5px 15px;
    margin: 5px 10px 5px 0px;
    text-align:left;
}

.quantity.selected{
    background-color:white;
    border:1px solid var(--main-purple);
    color:var(--main-purple);
}

.quantity:hover{
    cursor:pointer;
}

.count-down span {
  
  margin-left:10px;
  font-family:Lato;
}




@media only screen and (min-width: 1200px) {
  .bg4 {
    background-image: url('/assets/img/webp/Section 4.webp');
    content: url('/assets/img/webp/Section 4.webp');
  }
  ngx-gallery-thumbnails {
    margin-top: -20px !important;
  }
}

.stars img{
    max-width:100%;
    height:15px;
    margin-right:10px;
}

.w-35{
    width:35%;
}



.ngx-gallery {
  display: inline-block;
  margin-bottom: 20px;
 

  /* width: 500px;
     height: 400px;
     border-color: #4a89ff;*/
}
.gallery-wrapper {
  text-align: center;
  margin-top: 50px;

}
.ngx-gallery-image{
    background-size:contain !important;
}

.ngx-gallery-layout {
  width: auto !important;
  max-width: 100% !important;
  max-height: auto !important;
}
.ngx-gallery-thumbnail {
  border: none !important;
  max-height:120px !important;
}
.ngx-gallery-thumbnails-wrapper{
    max-height:100px !important;
}
ngx-gallery-thumbnails {
  height: calc(15% - 10px) !important;  
}
.choosePack.active{
    border-color:var(--main-purple) !important;
}
.choosePack {
  border: 1px solid #cfcfcf;
  position: relative;
  align-items: center;
  justify-content: center;
  height: 75px;
}

